import React from 'react'
import {Container, Row, Col, Jumbotron} from 'reactstrap'
import {Card, CardBody, CardTitle} from 'reactstrap'
import styled from 'styled-components'
import Layout from '../components/Layout'
import {Link} from 'react-router-dom'
import logo from '../components/logo_sm.png'

const MainJumbotron = styled(Jumbotron)`
  --jumbotron-padding-y: 3rem;
  padding-top: var(--jumbotron-padding-y);
  padding-bottom: var(--jumbotron-padding-y);
  margin-bottom: 0;
  border-radius: 0;
  background-color: #024465;

  @media (min-width: 768px) {
    padding-top: calc(var(--jumbotron-padding-y) * 2);
    padding-bottom: calc(var(--jumbotron-padding-y) * 1.5);
  }

  & > .container {
    max-width: 40rem;
  }

  & > .container > p:last-child {
    margin-bottom: 0;
  }

  & .jumbotron-heading {
    color: #f2f2f2;
    font-weight: 300;
  }
`

const InfoCard = ({title, children}) => (
  <Card className="my-card mb-4 shadow-sm" style={{height: '180px'}}>
    <CardBody>
      <CardTitle style={{fontSize: '20px', fontWeight: '500'}}>{title}</CardTitle>
      {children}
    </CardBody>
  </Card>
)

const CardList = styled.ul`
  margin-left: -15px;
`

export default () => (
  <Layout>
    <MainJumbotron>
      <Container className="text-center">
        <h1 className="jumbotron-heading">
          <img height="38" src={logo} alt="safeinfo logo" /> SafeInfo
        </h1>
        <p className="lead text-light">
          위생,안전 정보 모니터링 솔루션
          <br /> 원하는 센서를 설치하고 실시간 데이타를 바로 확인하세요.
        </p>
        <p>&nbsp;</p>
        <p>
          <a href="https://center.safeinfo.io" className="btn btn-outline-primary">
            관리 사이트 가기
          </a>
        </p>
      </Container>
    </MainJumbotron>
    <div className="album py-5 bg-light">
      <Container>
        <Row>
          <Col md="4">
            <InfoCard title="신뢰도 높은 센서">
              <CardList>
                <li>식중독 지수</li>
                <li>미세먼지</li>
                <li>냉장고 온습도</li>
              </CardList>
            </InfoCard>
          </Col>
          <Col md="4">
            <InfoCard title="손쉬운 모니터링">
              <CardList>
                <li>상황 정보 지도 등 시각화 제공</li>
                <li>손쉬운 검색기능</li>
                <li>데이타 통계</li>
              </CardList>
            </InfoCard>
          </Col>

          <Col md="4">
            <InfoCard title="부가기능">
              <CardList>
                <li>스마트폰 앱</li>
                <li>급식소 관리 기능</li>
              </CardList>
            </InfoCard>
          </Col>
        </Row>
      </Container>
    </div>
    <section className="jumbotron text-center">
      <Container>
        <Row>
          <Col md="12">
            <Link to="/guide" className="btn btn-primary">
              사용 가이드
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)
