import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import styled from 'styled-components'
import Layout from '../components/Layout'
import btImg1 from './btImg/bt-1.jpeg'
import btImg2 from './btImg/bt-2-2.jpeg'
import btImg3 from './btImg/bt-3.jpeg'
import btImg4 from './btImg/bt-4.jpeg'

const Pic = styled.img`
max-width: 80vw;
        max-height: 400px;
`

const GuideList = styled.ol`
padding-left: 26px;
      }
      & > li {
        padding-top: 26px;
      }
`

const PageTitle = styled.h1`
`

const PageContent = styled.div`
background-color: #fbfbfb;
        padding-top: 28px;
`

const GuideTitle = styled.h3`
margin-top: 20px;
`

export default () => (
  <Layout>
    <PageContent>
    <Container>
      <Row>
        <Col md="12">
          <PageTitle>사용 가이드</PageTitle>
          <GuideTitle>냉장고 건전지 교환 방법</GuideTitle>
          <div>
            <GuideList>
              <li>
                <p>냉장 냉도 구분</p>
                <Pic src={btImg1} alt="pic" />
              </li>
              <li>
                <p>상판 하판 분리</p>
                <Pic src={btImg2} alt="pic" />
              </li>
              <li>
                <p>AA건전지 교환</p>
                <Pic src={btImg3} alt="pic" />
              </li>
              <li>
                <p>냉장 냉동 구분하여 재장착</p>
                <Pic src={btImg4} alt="pic" />
              </li>
            </GuideList>
          </div>
        </Col>
      </Row>
    </Container>
    </PageContent>
  </Layout>
)
