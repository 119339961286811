import React from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import Index from './pages/Index'
import Guide from './pages/Guide'

const AppRouter = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={Index} />
      <Route path="/guide/" component={Guide} />
      <Redirect to="/" />
    </Switch>
  </Router>
)

export default AppRouter
