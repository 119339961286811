import React from 'react'
import logo from './logo_sm.png'
import './Layout.css'

function topFunction() {
  window.document.body.scrollTop = 0
  window.document.documentElement.scrollTop = 0 
}

const Footer = () => (
  <footer className="text-muted">
    <div className="container">
      <p className="float-right">
        <span className='btn btn-sm' onClick={topFunction}>위로가기</span>
      </p>
      <div className="copyright">
        <p>Copyright(c) 2018 인포나인 All Rights Reserved. </p>
        <p>상호명: 인포나인(주) / 대표이사: 이진곤</p>
        <p>
          연락처: <a href="tel:070-7562-6955">070-7562-6955</a> /{' '}
          <a href="tel:010-4320-2915">010-4320-2915</a> /{' '}
          <a href="mailto:info9x@naver.com">info9x@naver.com</a>
        </p>
      </div>
    </div>
  </footer>
)

export default props => (
  <React.Fragment>
    <header>
      <div className="navbar navbar-light shadow-sm">
        <div className="container d-flex justify-content-between">
          <a href="/" className="navbar-brand d-flex align-items-center">
            <img
              src={logo}
              alt="safeinfo-logo"
              width="24"
              height="24"
              style={{marginRight: '6px'}}
            />
            <strong>SafeInfo</strong>
          </a>
        </div>
      </div>
    </header>

    <main role="main" style={{minHeight: '50vh'}}>
      {props.children}
    </main>

    <Footer />
  </React.Fragment>
)
